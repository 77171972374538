<template>
  <b-table :data="info" striped :paginated="tableConfig.isPaginated" :total="info.length" :per-page="tableConfig.perPage"
    :backend-sorting="tableConfig.backendSorting" :opened-detailed="[1]" :detailed="tableConfig.isDetailed"
    :pagination-rounded="tableConfig.isPaginationRounded" :default-sort="detailKey" default-sort-direction="desc"
    :detail-key="detailKey" :detail-transition="'fade'" :show-detail-icon="true" @sort="onSort">
    <!--:page-input="tableConfig.isPaginatedInput"-->

    <b-table-column :key="key" :field="key" :label="value.label" v-slot="props" v-for="(value, key) in columns" centered
      :sortable="value.sortable" :visible="value.isVisible">
      <b-tag v-if="value.type === 'tag'">
        {{ getKeyText(key, props.row[key]) }}
      </b-tag>
      <template v-else-if="value.type === 'date'">
        {{ formatDate(props.row[key]) }}
      </template>
      <template v-else-if="value.type === 'dateIso'">
        {{ formatearFecha(props.row[key]) }}
      </template>
      <template v-else-if="value.type === 'detalles'">

        <div :id="props.row['id-compensar']">
          <div v-if="edicion === false && idseleccionado === props.row['id-compensar']">
            <a style="text-decoration: underline; color: #707070;" @click="mostrarAlertaeditable(props.row)">Detalle</a>
          </div>
          <div v-else :id="props.row['id-compensar']">
            <a style="text-decoration: underline; color: #707070;" @click="mostrarAlerta(props.row)">Detalle</a>
          </div>
        </div>
      </template>
      <template v-else-if="value.type === 'label'">
        <div :id="props.row['id-compensar']">
          <input class="labeled form-control rounded" v-if="edicion === false && idseleccionado === props.row['id-compensar']"
            @input="inputValue_Title = $event.target.value" type="text" :value="props.row['Title']"
            :id="props.row['id-compensar']"
            oninput="this.value = this.value.replace(/[^A-Za-zñÑáéíóúÁÉÍÓÚ ]/g, '').replace(/\s+/g, ' '); 
                                    if (this.value.trim() === '' || this.value === '0') this.value = 'No puede ser vacio'">
          <p v-else :id="props.row['id-compensar']">{{ props.row["Title"] }}</p>
        </div>
      </template>
      <template v-else-if="value.type === 'switched'">
        <div v-if="edicion === false && idseleccionado === props.row['id-compensar']" class="">
          <VueToggles height="27" width="115" ref="toggle" checkedText="Alfanumérico" uncheckedText="Numérico"
            checkedBg="#13b52d" uncheckedBg="#787777" v-model="state_Alfanumerico" :reverse="true"
            @click="cambioswitch(props.row['id-compensar'])" :disabled="value.disabled"/>
        </div>
        <div v-else :id="props.row['id-compensar']" class="">
          <button class="btn btn-outline-secondary  border-0" @click="cambioswitch(props.row['id-compensar'])" >
            <VueToggles height="27" width="115" ref="toggle" checkedText="Alfanumérico" uncheckedText="Numérico"
              checkedBg="#13b52d" uncheckedBg="#787777" v-model="props.row[key]" :reverse="true"/>
          </button>
        </div>
      </template>
      <template v-else-if="value.type === 'switchedestadoalertas'">
        <button class="btn btn-outline-secondary  border-0">
            <VueToggles height="27" width="115" ref="toggle" checkedText="Habilitado" uncheckedText="Inhabilitado" :disabled="value.disabled"
              checkedBg="#13b52d" uncheckedBg="#787777" :value="props.row[key]" @click="cambiarEstado(props, key)" :reverse="true"/>
        </button>
      </template>
      <template v-else-if="value.type === 'switchedUsuarios'">
        <div v-if="edicion === false && idseleccionado === props.row['id']" class="">
          <VueToggles height="27" width="115" ref="toggle" checkedText="Habilitado" uncheckedText="Inhabilitado"
            checkedBg="#13b52d" uncheckedBg="#dc3545" v-model="state_Alfanumerico" :reverse="true"
            @click="cambioswitch(props.row['id'])" :disabled="value.disabled"/>
        </div>
        <div v-else :id="props.row['id']" class="">
          <button class="btn btn-outline-secondary  border-0" @click="cambioswitch(props.row['id'])" >
            <VueToggles height="27" width="115" ref="toggle" checkedText="Habilitado" uncheckedText="Inhabilitado"
              checkedBg="#787777" uncheckedBg="#787777" v-model="props.row[key]" :reverse="true"/>
          </button>
        </div>
      </template>
      <template v-else-if="value.type === 'accionalertas'">
        <div :id="props.row['id']">
          <div :id="props.row['id']">
            <button class="btn btn-outline-secondary  border-0"
              @click="modalAlertas(props.row)">
              <i style="font-size: 30px; color: #666" class="bx bx-edit-alt"></i>
            </button>
          </div>
        </div>
        <div ref="modalalertas" class="modal">
          <div class="modal-background"></div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Editar {{ titleModal }}</p>
              <button class="delete" aria-label="close" @click="cerrarModalAlerta"></button>
            </header>
            <section class="modal-card-body">
              <div class="field">
                <label class="label">Mensaje</label>
                <div class="control">
                  <textarea class="textarea" placeholder="Mensaje" v-model="mensajeAlert"></textarea>
                </div>
              </div>
              <div class="field">
                <label class="label">Fecha de Inicio</label>
                <div class="control">
                  <input class="input" type="datetime-local" v-model="fechaIniAlert">
                </div>
              </div>
              <div class="field">
                <label class="label">Fecha de Fin</label>
                <div class="control">
                  <input class="input" type="datetime-local" v-model="fechaFinAlert" :min="minPermitidoFechas">
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <b-button class="column" type="is-primary" expanded @click="guardarAlerta()">
                Guardar
              </b-button>
              <b-button class="column"  type="is-danger" expanded @click="cerrarModalAlerta">
                Cancelar
              </b-button>
            </footer>
          </div>
        </div>
      </template>
      <template v-else-if="value.type === 'accionCheck'">
        <div :id="props.row['id']">
          <b-field>
            <div class="control">
              <b-checkbox v-model="props.row[key]" :value="props.row[key]" >
              </b-checkbox>
            </div>
          </b-field>
        </div>
      </template>
      <template v-else-if="value.type === 'accion'">
        <div :id="props.row['id-compensar']">
          <div v-if="edicion === false && idseleccionado === props.row['id-compensar']">
            <button class="btn btn-outline-secondary  border-0" @click=confirmar>
              <i style="font-size: 30px; color: #666" class="bx bx-check-circle"></i>
            </button>
            <button class="btn btn-outline-secondary  border-0"
              @click="lapiz(props.row['id-compensar'], props.row['Title'])">
              <i style="font-size: 30px; color: #666" class="bx bx-x-circle"></i>
            </button>
          </div>
          <div v-else :id="props.row['id-compensar']">
            <button class="btn btn-outline-secondary  border-0"
              @click="lapiz(props.row['id-compensar'], null, props.row['AlfaNumerico'], props.row['Title'], props.row)">
              <i style="font-size: 30px; color: #666" class="bx bx-edit-alt"></i>
            </button>
          </div>
        </div>

      </template>

      <template v-else-if="value.type === 'accionUsuarios'">
        <div :id="props.row['id']">
          <div v-if="edicion === false && idseleccionado === props.row['id']">
            <button class="btn btn-outline-secondary  border-0" @click=confirmarCambios>
              <i style="font-size: 30px; color: #666" class="bx bx-check-circle"></i>
            </button>
            <button class="btn btn-outline-secondary  border-0"
              @click="editar(props.row['id'])">
              <i style="font-size: 30px; color: #666" class="bx bx-x-circle"></i>
            </button>
          </div>
          <div v-else :id="props.row['id']">
            <button class="btn btn-outline-secondary  border-0"
              @click="editar(props.row['id'], props.row['state'])">
              <i style="font-size: 30px; color: #666" class="bx bx-edit-alt"></i>
            </button>
          </div>
        </div>

      </template>
      <template v-else-if="value.type === 'composedDate'">
        {{ formatDate(`${props.row[value.keys[0]]} ${props.row[value.keys[1]]}`) }}
      </template>
      <b-tag v-else-if="value.type === 'composedScoreDesc'"
        :class="getTagFaceText(props.row[value.keys[0]], props.row[value.keys[1]])">
        {{ props.row[key] }}
      </b-tag>
      <b-tag v-else-if="value.type === 'composedScoreAnswer'" :class="getTagFaceYn(props.row[value.keys])">
        {{ props.row[value.keys] }}
      </b-tag>
      <b-tag :type="getTagState(props.row[getStatusKey(key)])" v-else-if="value.type === 'status'">
        {{ getStatusText(props.row[getStatusKey(key)]) }}
      </b-tag>
      <b-tag v-else-if="value.type === 'tipo'">
        {{ getTipoDoc(props.row[key]) }}
      </b-tag>
      <b-tag v-else-if="value.type === 'statusRegistraduria'" :type="tagRegistraduria(props.row[getStatusKey(key)])">
        {{ props.row[key].replace("_", " ") }}
      </b-tag>
      <b-tag :type="props.row[getStatusKey(key)] ? 'is-success' : 'is-danger'" v-else-if="value.type === 'statusBool'">
        {{ props.row[getStatusKey(key)] ? "Habilitado" : "Desabilitado" }}
      </b-tag>
      <template v-else-if="value.type === 'composedText'">
        {{ `${props.row[value.keys[0]]} ${props.row[value.keys[1]]}` }}
      </template>
      <b-tag :type="getStatusTag(props.row[value.keys[0]], props.row[value.keys[1]], props.row[key])"
        v-else-if="value.type === 'composedState'">
        {{ getStatus(props.row[key]) }}
      </b-tag>
      <template v-else-if="value.type === 'percentage'">
        {{ percentFormatted(props.row[key]) }}
      </template>
      <template v-else-if="value.type === 'message'">
        {{ messageFormatting(`${props.row[key]}`, key) }}
      </template>
      <template v-else-if="value.type === 'portal'">
        {{ getPortal(`${props.row[key]}`, key) }}
      </template>
      <template v-else-if="value.type === 'alertError'">
        <span v-bind:style="{ cursor: 'pointer' }" @click="getError(props.row[value.keys[0]], props.row[value.keys[1]])">
          {{ props.row[key] }}
        </span>
      </template>
      <div class="buttons" style="width: 120px; margin-left: auto; margin-right: auto"
        v-else-if="value.type === 'buttonGroup'">
        <b-button class="is-light" :key="item.icon" :type="item.properties"
          @click="setToAction(item.action, props.row[item.key])" v-for="item of value.data">
          <template v-if="item.label">
            {{ item.label }}
          </template>
          <i :class="item.icon" v-if="item.icon" />
        </b-button>
      </div>
      <template v-else>
        {{ getKeyText(key, props.row[key]) }}
      </template>
    </b-table-column>
    <template #bottom-left>
      <b>Total registros encontrados</b>: {{ info.length }}
    </template>
    <template v-if="tableConfig.isDetailed" #detail="props">
      <article class="media">
        <div class="media-content">
          <div class="content">
            <h3>Información adicional</h3>
            <hr>
            <p>
              <FirstCardDetail v-if="type === 1" :props="props"></FirstCardDetail>
              <SecondCardDetail v-if="type === 2" :props="props"></SecondCardDetail>
              <ThirdCardDetail v-if="type === 3" :props="props"></ThirdCardDetail>
              <FourthCardDetail v-if="type === 4" :props="props"></FourthCardDetail>
              <DelegationCardDetail v-if="type === 5" :props="props" :ciamId="ciamId" :represent="represent"></DelegationCardDetail>
            </p>
          </div>
        </div>
      </article>
    </template>
    <slot></slot>
  </b-table>
</template>

<script>
import {
  dateFormatting,
  getTagState,
  tagRegistraduria,
  getStatusText,
  typeDocFormatting,
  numDocFormatted,
  getTagFace,
  getStatusFace,
  getTagFaceYN,
  getStatusFaceTwo, getStatusTagSOP, percentFormatted, messageFormatting, getPortal
} from "../../assets/config/utilities";
import { STATES } from "../../assets/config/constants/GeneralConstants";
import Swal from 'sweetalert2';
import VueToggles from 'vue-toggles';
import axios from 'axios';
const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento;
  const urlTiposdeDocumento = config.urlTiposdeDocumento;
import { config } from "../../assets/config/jsonconfig.json";
const Api_Types = config["Api-Key-types"]
import { getToken } from "../../assets/config/getToken.js";

//import { CompatSource } from "webpack-sources";
//import { config } from "../../assets/config/jsonconfig.json";
//import axios from "axios";

//const urlBaseTiposdeDocumento = config.urlBaseTiposdeDocumento;


export default {
  async mounted() {
    this.token = await getToken(config.DOMINIO);
  },
  data() {
    return {
      titleModal: '',
      state_Alfanumerico: null,
      primeravez: true,
      aux_min_max: [],
      value: false,
      edicion: "estatico",
      isActive: true, // valor inicial,
      mensajeAlert: null,
      fechaIniAlert: null,
      fechaFinAlert: null,
      id: null,
      minPermitidoFechas: new Date().toISOString().slice(0, 16),
      token: null
    }
  },
  name: 'CustomTable',
  components: {
    VueToggles,
    FirstCardDetail: () => import("./cardDetail/FirstCardDetail"),
    SecondCardDetail: () => import("./cardDetail/SecondCardDetail"),
    ThirdCardDetail: () => import("./cardDetail/ThirdCardDetail"),
    FourthCardDetail: () => import("./cardDetail/FourthCardDetail"),
    DelegationCardDetail: () => import("./cardDetail/DelegationCardDetail"),
  },
  props: {
    columns: Object,
    info: undefined,
    detailKey: String,
    ciamId: String,
    represent: String,
    tableConfig: {
      type: Object,
      default: () => {
        return {
          agregar: true,
          idseleccionado: 0,
          minseleccionado: 0,
          edicion: true,
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: true,
        }
      }
    },
    type: {
      type: Number,
      default: 1
    },
  },
  methods: {

    lapiz(id, campo, paramAlfanumeric, Title, data) {
      this.state_Alfanumerico = null
      this.inputValue_Title = Title
      this.primeravez = true
      this.valor = campo;
      this.idseleccionado = id;
      this.edicion = !this.edicion;
      this.state_Alfanumerico = paramAlfanumeric
      this.aux_min_max = []
      this.aux_min_max[0] = data.min['Administracion de Delegados'];
      this.aux_min_max[1] = data.max['Administracion de Delegados'];
      this.aux_min_max[2] = data.min['Autenticacion'];
      this.aux_min_max[3] = data.max['Autenticacion'];
      this.aux_min_max[4] = data.min['Creacion'];
      this.aux_min_max[5] = data.max['Creacion'];
      this.aux_min_max[6] = data.min['Recuperacion'];
      this.aux_min_max[7] = data.max['Recuperacion'];
      this.aux_min_max[8] = data.min['Representante Legal'];
      this.aux_min_max[9] = data.max['Representante Legal'];

    },
    editar(id, paramAlfanumeric) {
      this.state_Alfanumerico = null;
      this.primeravez = true;
      this.idseleccionado = id;
      this.edicion = !this.edicion;
      this.state_Alfanumerico = paramAlfanumeric;
    },
    cambioswitch(id) {
      if (this.idseleccionado === id) {
        this.state_Alfanumerico = !this.state_Alfanumerico
      }
    },
    cambiarEstado(props, key) {
      props.row[key] = !props.row[key];
    },
    modalAlertas(data) {
      console.log(data, this.$refs.modalalertas);

      this.titleModal = data['tipo_mensaje']
      this.id = data['id']
      this.mensajeAlert = data['mensaje']
      this.fechaIniAlert = data['fecha_inicio']
      this.fechaFinAlert = data['fecha_fin']
      this.$refs.modalalertas[0].classList.add('is-active');
    },
    cerrarModalAlerta() {
      this.$buefy.dialog.confirm({
        title: 'Alertas',
        message: 'Si cancela, perderá los cambios realizados',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
        type: 'is-primary',
        hasIcon: false,
        onConfirm: () => {
          this.$refs.modalalertas[0].classList.remove('is-active');
        }
      })
    },
    guardarAlerta() {
      const fechaInput1 = this.fechaIniAlert;
      const fechaInput2 = this.fechaFinAlert;
      const msg = this.mensajeAlert;
      
      const fechaIni = new Date(fechaInput1);
      const fechaFin = new Date(fechaInput2);
      if (!fechaIni || !fechaFin || !msg) {
        this.$buefy.dialog.confirm({
          title: 'Alertas',
          message: 'Validar fechas y mensaje',
          confirmText: 'Entiendo',
          cancelText: 'Cancelar',
          type: 'is-primary',
          hasIcon: false,
          onConfirm: () => {}
        })
      }else if (fechaIni >= fechaFin) {
        this.$buefy.dialog.confirm({
          title: 'Alertas',
          message: 'La fecha inicio es mayor a la fin',
          confirmText: 'Entiendo',
          cancelText: 'Cancelar',
          type: 'is-primary',
          hasIcon: false,
          onConfirm: () => {}
        })
      }else {
        for (const i in this.info) {
          let valor = this.info[i]
          if (valor.id == this.id) {
            valor.mensaje = this.mensajeAlert;
            valor.fecha_inicio = this.fechaIniAlert;
            valor.fecha_fin = this.fechaFinAlert;
            valor.estado_habilitado = true;

            this.$refs.modalalertas[0].classList.remove('is-active');
            break;
          }
        }
      }
    },
    formatearFecha(fechaHoraISO) {
      const fechaHora = new Date(fechaHoraISO);
      let dia = fechaHora.getDate();
      let mes = fechaHora.getMonth() + 1;
      const anio = fechaHora.getFullYear();
      const horas = fechaHora.getHours();
      let minutos = fechaHora.getMinutes();
      const ampm = horas >= 12 ? 'pm' : 'am';
      let horas12 = horas > 12 ? horas - 12 : horas;

      dia = dia < 10 ? '0' + dia : dia;
      mes = mes < 10 ? '0' + mes : mes;

      minutos = minutos < 10 ? '0' + minutos : minutos;
      horas12 = horas12 < 10 ? '0' + horas12 : horas12;

      return `${dia}/${mes}/${anio} ${horas12}:${minutos} ${ampm}`;
    },
    async confirmarCambios() {
      const result = await Swal.fire({
        title: '¿Estás seguro de realizar los cambios?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        reverseButtons: true
      })
      if (result.isConfirmed) {
        this.guardarCambios()
      }
    },
    async guardarCambios() {
      const params = {
        "state": this.state_Alfanumerico,
      }
      try {
        await axios.put(urlTiposdeDocumento + "/synthetic-users/"+this.idseleccionado, params,
          {
            headers: {
              'x-api-key': config["x-api-key"]
            }
          }
        )
        const result = await Swal.fire({
          title: 'Cambios realizados exitosamente',
          icon: 'success',
          confirmButtonText: 'OK'
        })

        if (result.isConfirmed) {
          location.reload();
        } 

      } catch (error) {
        const result = await Swal.fire({
          title: 'Error en la base de datos - '+ error.response.data.error,
          icon: 'error',
          confirmButtonText: 'OK'
        })

        if (result.isConfirmed) {
          console.error(error);
        } 
        console.error(error);
      }

    },
    async confirmar() {
      const result = await Swal.fire({
        title: '¿Estás seguro de los cambios?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        reverseButtons: true
      })
      if (result.isConfirmed) {
        this.guardardata()
      }
    },
    async guardardata() {
      const params = {
        "Title": this.inputValue_Title,
        "AlfaNumerico": this.state_Alfanumerico,
        "Usuario": `${this.$store.state.usuario}`,
        "id-compensar": this.idseleccionado,
        "max": {
          "Administracion de Delegados": parseInt(this.aux_min_max[1]),
          "Autenticacion": parseInt(this.aux_min_max[3]),
          "Creacion": parseInt(this.aux_min_max[5]),
          "Recuperacion": parseInt(this.aux_min_max[7]),
          "Representante Legal": parseInt(this.aux_min_max[9])
        },
        "min": {
          "Administracion de Delegados": parseInt(this.aux_min_max[0]),
          "Autenticacion": parseInt(this.aux_min_max[2]),
          "Creacion": parseInt(this.aux_min_max[4]),
          "Recuperacion": parseInt(this.aux_min_max[6]),
          "Representante Legal": parseInt(this.aux_min_max[8])
        }

      }
      try {
        await axios.put(urlBaseTiposdeDocumento + "/parametrization", params,
          {
            headers: {
              'Api-Key': Api_Types,
              'Authorization': `Bearer ${this.token.Bearer}`
            }
          }
        )
        const result = await Swal.fire({
          title: 'Cambios realizados exitosamente',
          icon: 'success',
          confirmButtonText: 'OK'
        })

        if (result.isConfirmed) {
          location.reload();
        } 

      } catch (error) {
        const result = await Swal.fire({
          title: 'Error en la base de datos - '+ error.response.data.error,
          icon: 'error',
          confirmButtonText: 'OK'
        })

        if (result.isConfirmed) {
          console.error(error);
        } 
        console.error(error);
      }

    },
    async mostrarAlerta(data) {
      const result = await Swal.fire({
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true, //
        html: `<style> .margen{
                padding: 12px;
                
              } .borde-superior {
                border-top: 2px solid black;
              }
              </style>
        <table>
          <thead>
            <tr>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Funcionalidad</th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Longitud mínima</th>
              <th></th>
              <th style="width: 320px;vertical-align: inherit;height: 64px;  white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Longitud máxima</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="margen borde-superior" style="max-width: 2050px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Administración de Delegados</td>
              <td class="margen borde-superior" >${data.min['Administracion de Delegados']}</td>
              <td class="margen borde-superior" >-</td>
              <td class="margen borde-superior" >${data.max['Administracion de Delegados']}</td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 2050px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Autenticación</td>
              <td class="margen" >${data.min['Autenticacion']}</td>
              <td class="margen" >-</td>
              <td class="margen" >${data.max['Autenticacion']}</td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 2050px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Creación</td>
              <td class="margen">${data.min['Creacion']}</td>
              <td class="margen">-</td>
              <td class="margen">${data.max['Creacion']}</td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 2050px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Recuperación</td>
              <td class="margen">${data.min['Recuperacion']}</td>
              <td class="margen" >-</td>
              <td class="margen">${data.max['Recuperacion']}</td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Representante legal</td>
              <td class="margen" >${data.min['Representante Legal']}</td>
              <td class="margen" >-</td>
              <td class="margen" >${data.max['Representante Legal']}</td>
            </tr>
          </tbody>
        </table>
      `,
        customClass: {
          container: 'my-swal-container',
          popup: 'my-swal-popup'
        },
      })
      if (result.isConfirmed) {
        this.guardar()
      }
    },
    async mostrarAlertaeditable(data) {
      if (this.primeravez === true) {
        this.aux_min_max = []
        const result = await Swal.fire({
          showCancelButton: true,
          html: `<style> .margen{
                padding: 12px;
              } .borde-superior {
                border-top: 2px solid black;
              }
              </style>
        <table>
          <thead style="width: 320px">
            <tr>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Funcionalidad</th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Longitud mínima</th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"></th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Longitud máxima</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="margen borde-superior" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Administración de Delegados</td>
              <td class="margen borde-superior" ><input type="text"  class="swal2-inputva" style="width: 40px" value=${data.min['Administracion de Delegados']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen borde-superior" >-</td>
              <td class="margen borde-superior" ><input type="text"  class="swal2-inputva" style="width: 40px" value=${data.max['Administracion de Delegados']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Autenticación</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${data.min['Autenticacion']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text"  class="swal2-inputva"style="width: 40px" value=${data.max['Autenticacion']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Creación</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${data.min['Creacion']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text"  class="swal2-inputva" style="width: 40px" value=${data.max['Creacion']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Recuperación</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${data.min['Recuperacion']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${data.max['Recuperacion']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Representante legal</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${data.min['Representante Legal']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${data.max['Representante Legal']} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
          </tbody>
        </table>
      `,
          customClass: {
            container: 'my-swal-container',
            popup: 'my-swal-popup'
          },
          cancelButtonText: 'Descartar',
          confirmButtonText: 'Confirmar',
          cancelButtonColor: '#d33',
          reverseButtons: true
        })
        if (result.isConfirmed) {
          this.primeravez = false;
          const inputs = document.querySelectorAll('.swal2-inputva');
          this.aux_min_max = []
          this.aux_min_max[0] = inputs[0].value;
          this.aux_min_max[1] = inputs[1].value;
          this.aux_min_max[2] = inputs[2].value;
          this.aux_min_max[3] = inputs[3].value;
          this.aux_min_max[4] = inputs[4].value;
          this.aux_min_max[5] = inputs[5].value;
          this.aux_min_max[6] = inputs[6].value;
          this.aux_min_max[7] = inputs[7].value;
          this.aux_min_max[8] = inputs[8].value;
          this.aux_min_max[9] = inputs[9].value;

        }


      } else {
        this.mostrarAlertaeditableaux_min_max(data, this.aux_min_max)

      }
    },
    async mostrarAlertaeditableaux_min_max(data, aux_min_max) {
      if (this.primeravez === true) {
        this.primeravez = false
        this.aux_min_max = []

      } else {
        const result = await Swal.fire({
          showCancelButton: true,
          html: `<style> .margen{
                padding: 12px;
              } .borde-superior {
                border-top: 2px solid black;
              }
              </style>
        <table>
          <thead>
            <tr>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Funcionalidad</th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Longitud mínima</th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"></th>
              <th style="width: 320px;vertical-align: inherit;height: 64px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Longitud máxima</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="margen borde-superior" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Administración de Delegados</td>
              <td class="margen borde-superior" ><input type="text"  class="swal2-inputva" style="width: 40px" value=${aux_min_max[0]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen borde-superior" >-</td>
              <td class="margen borde-superior" ><input type="text"  class="swal2-inputva" style="width: 40px" value=${aux_min_max[1]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Autenticación</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${aux_min_max[2]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text"  class="swal2-inputva"style="width: 40px" value=${aux_min_max[3]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Creación</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${aux_min_max[4]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text"  class="swal2-inputva" style="width: 40px" value=${aux_min_max[5]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td  class="margen"style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Recuperación</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${aux_min_max[6]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${aux_min_max[7]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
            <tr>
              <td class="margen" style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Representante legal</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${aux_min_max[8]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.min['Administracion de Delegados']};"></td>
              <td class="margen">-</td>
              <td class="margen"><input type="text" class="swal2-inputva" style="width: 40px" value=${aux_min_max[9]} oninput="this.value = this.value.replace(/[^0-9]/g, '').substring(0,2) ; if (this.value.trim() === '' || this.value === '0') this.value = ${data.max['Administracion de Delegados']};"></td>
            </tr>
          </tbody>
        </table>
      `,
          customClass: {
            container: 'my-swal-container',
            popup: 'my-swal-popup'
          },
          cancelButtonText: 'Descartar',
          confirmButtonText: 'Confirmar',
          cancelButtonColor: '#d33',
          reverseButtons: true
        })
        if (result.isConfirmed) {
          this.primeravez = false;
          const inputs = document.querySelectorAll('.swal2-inputva');
          this.aux_min_max[0] = parseInt(inputs[0].value);
          this.aux_min_max[1] = parseInt(inputs[1].value);
          this.aux_min_max[2] = parseInt(inputs[2].value);
          this.aux_min_max[3] = parseInt(inputs[3].value);
          this.aux_min_max[4] = parseInt(inputs[4].value);
          this.aux_min_max[5] = parseInt(inputs[5].value);
          this.aux_min_max[6] = parseInt(inputs[6].value);
          this.aux_min_max[7] = parseInt(inputs[7].value);
          this.aux_min_max[8] = parseInt(inputs[8].value);
          this.aux_min_max[9] = parseInt(inputs[9].value);
        }
      }
    },
    getPortal(serviceProvider) {
      return getPortal(serviceProvider)
    },
    formatDate(date) {
      return dateFormatting(date);
    },
    getStatus(status) {
      return STATES[status].label
    },
    getStatusTag(scoreConfigured, scoreTotal, status) {
      return getStatusTagSOP(scoreConfigured, scoreTotal, status);
    },
    getTagState(status) {
      return getTagState(status);
    },
    tagRegistraduria(status){
      tagRegistraduria(status)
    },
    getStatusText(status) {
      return getStatusText(status);
    },
    typeDocFormatting(user) {
      return typeDocFormatting(user);
    },
    numDocFormatting(user) {
      return numDocFormatted(user);
    },
    messageFormatting(message, key) {
      return messageFormatting(message, this.columns[key].messageType ? this.columns[key].messageType : 'default');
    },
    percentFormatted(number) {
      return percentFormatted(number);
    },
    getTagFaceText(scoreConfigured, scoreTotal) {
      return getTagFace(scoreConfigured, scoreTotal);
    },
    getStatusFaceText(scoreConfigured, scoreTotal) {
      return getStatusFace(scoreConfigured, scoreTotal);
    },
    getTagFaceYn(scoreConfigured) {
      return getTagFaceYN(scoreConfigured);
    },
    getTagFaceTwoText(scoreConfigured) {
      return getStatusFaceTwo(scoreConfigured);
    },
    getStatusKey(key) {
      return this.columns[key].keys ? this.columns[key].keys[0] : key;
    },
    getKeyText(key, value) {
      return value ? value : (this.columns[key].alternMessage ? this.columns[key].alternMessage : value);
    },
    onSort(field, order) {
      this.info = this.info.sort((a, b) => {
        let result;
        if (this.columns[field].type === 'date') {
          result = new Date(a[field]) - new Date(b[field]);
        } else if (this.columns[field].type === 'composedDate') {
          const keys = this.columns[field].keys;
          result = new Date(`${a[keys[0]]} ${a[keys[1]]}`) - new Date(`${b[keys[0]]} ${b[keys[1]]}`);
        } else {
          if (a[field] < b[field]) {
            result = -1;
          }
          else if (a[field] > b[field]) {
            result = 1;
          }
          else {
            result = 0;
          }
        }
        return order === 'desc' ? result * -1 : result;
      })
    },
    getError(error, responseLastEvent) {
      const content = "<b> " + "Código respuesta: " + "</b> " + error + "</br><b> " + "Descripción:  " + "</b> " + responseLastEvent;
      this.$buefy.dialog.alert({
        title: "Último evento",
        message: content,
        hasIcon: false,
        ariaRole: "alertdialog",
        ariaModal: true,
      });
      return true
    },
    getTipoDoc(status) {
      let map = new Map();
      map.set("1", "Cédula de ciudadanía");
      map.set("4", "Cédula de extranjería");
      map.set("NIT", "Número de identificación tributaria");
      map.set("N.E", "Nit de extranjería");
      return map.get(status);
    },
    setToAction(actionStr, id) {
      if (actionStr === 'delete') {
        this.deleteItem(id)
      }
      else if (actionStr === 'historico'){
        this.historicoItem(id)
      }
      else {
        this.editItem(id)
      }
    },
    deleteItem(id) {
      this.$emit('delete', id);
    },
    editItem(id) {
      this.$emit('edit', id);
    },
    historicoItem(id){
      this.$emit('historico', id);
    }
  }
}
</script>

<style>
.btn-outline-secondary {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  outline: none; 
}
.my-swal-popup {
  width: 800px;
}
.labeled {
  border-radius: 5px;
  border: 1px solid grey;
  padding: 5px;
}
</style>